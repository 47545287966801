import { defineNuxtPlugin } from "#app";
import posthog, { type PostHog } from "posthog-js";
export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const { posthogPublicKey, posthogHost, enablePosthog } = runtimeConfig.public;
  let posthogClient: PostHog | void;
  console.log("Enable Posthog => ", enablePosthog);
  if (enablePosthog) {
    posthogClient = posthog.init(`${posthogPublicKey}`, {
      api_host: posthogHost || "https://app.posthog.com",
      capture_pageview: false, // we add manual pageView capturing below
      loaded: (posthog) => {
        if (import.meta.env.MODE === "development") posthog.debug();
      },
    });

    // Make sure that pageViews are captured with each route change
    const router = useRouter();
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture("$pageview", {
          current_url: to.fullPath,
        });
      });
    });
  }

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
