import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrow, LazySvgoCalendar, LazySvgoCheck, LazySvgoChevronRight, LazySvgoClose, LazySvgoCopy, LazySvgoCorrectCircle, LazySvgoCorrect, LazySvgoErrorCircle, LazySvgoMonoLogo, LazySvgoPriceTag, LazySvgoTrash, LazySvgoVuePdfViewerHighlightIcon, LazySvgoVuePdfViewerWhyIcon } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrow", LazySvgoArrow],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCheck", LazySvgoCheck],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoClose", LazySvgoClose],
["SvgoCopy", LazySvgoCopy],
["SvgoCorrectCircle", LazySvgoCorrectCircle],
["SvgoCorrect", LazySvgoCorrect],
["SvgoErrorCircle", LazySvgoErrorCircle],
["SvgoMonoLogo", LazySvgoMonoLogo],
["SvgoPriceTag", LazySvgoPriceTag],
["SvgoTrash", LazySvgoTrash],
["SvgoVuePdfViewerHighlightIcon", LazySvgoVuePdfViewerHighlightIcon],
["SvgoVuePdfViewerWhyIcon", LazySvgoVuePdfViewerWhyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
