export default defineNuxtRouteMiddleware((to, from) => {
  console.log(`from: ${from.fullPath}, to: ${to.fullPath}`);
  let lastTop: number;
  if (import.meta.client) {
    lastTop = history.state.scroll?.top ?? 0;
  }
  useNuxtApp().hook("page:loading:end", () => {
    if (to.query) {
      return;
    }
    if (lastTop) {
      window.scrollTo({ top: lastTop });
      lastTop = 0;
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  });
});
