import revive_payload_client_U0YKSMyMJd from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pIr8WEj5RE from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4UpFnc6Wja from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IA52lk05sL from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.20.0_vite@5.3.5_vue@3.4.36/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_d3Y4ndsULD from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BanqfQLMW2 from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IWMArK3q68 from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mr2awOcDvT from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/corp-app/.nuxt/components.plugin.mjs";
import prefetch_client_qL64FBimL1 from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_qBaXdKId6A from "/app/node_modules/.pnpm/@sentry+nuxt@8.32.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_wbxxztcf7cik74kwl3rrly6it4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/apps/corp-app/.nuxt/sentry-client-config.mjs";
import plugin_client_jU91LoJxzF from "/app/node_modules/.pnpm/nuxt-gtag@2.1.0_rollup@4.20.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_qLuEGzQKWN from "/app/node_modules/.pnpm/nuxt-aos@1.2.5_rollup@4.20.0_vite@5.3.5/node_modules/nuxt-aos/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/app/apps/corp-app/.nuxt/unocss.mjs";
import posthog_client_fUucxKWhZE from "/app/apps/corp-app/plugins/posthog.client.ts";
export default [
  revive_payload_client_U0YKSMyMJd,
  unhead_pIr8WEj5RE,
  router_4UpFnc6Wja,
  _0_siteConfig_IA52lk05sL,
  payload_client_d3Y4ndsULD,
  navigation_repaint_client_BanqfQLMW2,
  check_outdated_build_client_IWMArK3q68,
  chunk_reload_client_mr2awOcDvT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qL64FBimL1,
  sentry_client_qBaXdKId6A,
  sentry_client_config_o34nk2sJbg,
  plugin_client_jU91LoJxzF,
  plugin_qLuEGzQKWN,
  unocss_MzCDxu9LMj,
  posthog_client_fUucxKWhZE
]