import { default as authGNuOltbRreMeta } from "/app/apps/corp-app/pages/auth.vue?macro=true";
import { default as example_45licenseM9Pf4rgnBPMeta } from "/app/apps/corp-app/pages/example-license.vue?macro=true";
import { default as exampleiJjfeKXli0Meta } from "/app/apps/corp-app/pages/example.vue?macro=true";
import { default as expiration_45linkXjm6BTVRIKMeta } from "/app/apps/corp-app/pages/expiration-link.vue?macro=true";
import { default as indexctPYnUturnMeta } from "/app/apps/corp-app/pages/features/index.vue?macro=true";
import { default as indexazkZc8VhSCMeta } from "/app/apps/corp-app/pages/index.vue?macro=true";
import { default as indexO527rw9nWAMeta } from "/app/apps/corp-app/pages/license-agreement/index.vue?macro=true";
import { default as _91_91licenseId_93_93gabeQ0ThiTMeta } from "/app/apps/corp-app/pages/license-management/domain/[[licenseId]].vue?macro=true";
import { default as index5BsTIA71WwMeta } from "/app/apps/corp-app/pages/license-management/index.vue?macro=true";
import { default as indexTlG1465v1KMeta } from "/app/apps/corp-app/pages/privacy/index.vue?macro=true";
import { default as indexeK2zcgZtgeMeta } from "/app/apps/corp-app/pages/purchase/index.vue?macro=true";
import { default as developeraBbT9cUVfEMeta } from "/app/apps/corp-app/pages/redirect-to-pay/developer.vue?macro=true";
import { default as organizationjoLlrhW8goMeta } from "/app/apps/corp-app/pages/redirect-to-pay/organization.vue?macro=true";
import { default as indexLxPX3jOd8kMeta } from "/app/apps/corp-app/pages/terms-and-conditions/index.vue?macro=true";
import { default as component_45stubIqqLUKYIx0Meta } from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubIqqLUKYIx0 } from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@9.8.0_rollup@4.20.0_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: authGNuOltbRreMeta || {},
    component: () => import("/app/apps/corp-app/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "example-license",
    path: "/example-license",
    meta: example_45licenseM9Pf4rgnBPMeta || {},
    component: () => import("/app/apps/corp-app/pages/example-license.vue").then(m => m.default || m)
  },
  {
    name: "example",
    path: "/example",
    meta: exampleiJjfeKXli0Meta || {},
    component: () => import("/app/apps/corp-app/pages/example.vue").then(m => m.default || m)
  },
  {
    name: "expiration-link",
    path: "/expiration-link",
    component: () => import("/app/apps/corp-app/pages/expiration-link.vue").then(m => m.default || m)
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/app/apps/corp-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/apps/corp-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "license-agreement",
    path: "/license-agreement",
    component: () => import("/app/apps/corp-app/pages/license-agreement/index.vue").then(m => m.default || m)
  },
  {
    name: "license-management-domain-licenseId",
    path: "/license-management/domain/:licenseId?",
    component: () => import("/app/apps/corp-app/pages/license-management/domain/[[licenseId]].vue").then(m => m.default || m)
  },
  {
    name: "license-management",
    path: "/license-management",
    component: () => import("/app/apps/corp-app/pages/license-management/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/app/apps/corp-app/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/app/apps/corp-app/pages/purchase/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-pay-developer",
    path: "/redirect-to-pay/developer",
    component: () => import("/app/apps/corp-app/pages/redirect-to-pay/developer.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-pay-organization",
    path: "/redirect-to-pay/organization",
    component: () => import("/app/apps/corp-app/pages/redirect-to-pay/organization.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/app/apps/corp-app/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubIqqLUKYIx0Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubIqqLUKYIx0
  }
]